///
///  Example
///    <div id="layout-auth">
///      <header id="layout-auth-header"></header>
///      <main id="layout-auth-main"></main>
///    </div>
///

#layout-auth {
  &-page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }

  &-header {
    position: relative;
    flex: 0 1 auto;
  }

  &-main {
    position: relative;
    flex: 1 1 auto;

    .twofa-step {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      max-width: 620px;
      height: calc(100vh - 70px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
